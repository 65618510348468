.loader {
  width: 100%;
  height: 4.8px;
  display: inline-block;
  position: relative;
  background: rgba(0, 0, 0, 0.15);
  overflow: hidden;
  min-width: 18rem;
}
.loader::after {
  content: '';
  width: 96px; 
  height: 4.8px;
  background: #1876d2;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: hitZak 1s linear infinite alternate;
}

@keyframes hitZak {
  0% {
    left: 0;
    transform: translateX(0%);
  }
  100% {
    left: 100%;
    transform: translateX(-100%);
  }
}
/* Slide-up animation */
@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Slide-left animation */
@keyframes slideLeft {
  from {
    transform: translateX(20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-up {
  animation: slideUp 1s ease-in-out;
}

.slide-left {
  animation: slideLeft 1s ease-in-out;
}
